import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { Link } from 'gatsby';

export const Anchor = styled.a`
  text-decoration: none;
`;
export const NoAnchorDiv = styled.div``;
export const AnchorInternal = styled(Link)`
  text-decoration: none;
`;
export const LinkStyle = styled.a`
  text-decoration: none;
  color: #9fb577;
`;

export const BlockTitle = styled.h2`
  width: 280px;
  margin: 0px;
  padding: 16px 30px 14px 30px;
  font-family: 'Tungsten-Medium', arial, sans-serif;
  font-size: 22px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffde00;
  background: #33a23c;
  letter-spacing: 0.05em;
`;

export const BlockArea = styled.div`
  padding: 25px 26px;
  background: #fff;
  border-top: none !important;
`;

export const BlockContainer = styled.div`
  @media print {
    display: none;
  }
  display: block;
  ${BlockArea} {
    border: 4px solid #e1f1e2;
  }
  :hover {
    ${BlockArea} {
      border: 4px solid #33a23c;
    }
  }
  @media (max-width: 992px) {
    margin-bottom: 30px;
  }
`;

export const CardCol = styled(Col)`
  justify-content: start;
  overflow: hidden;
`;
export const Heading3 = styled.h3`
  margin: 0 0 20px 0;
  font-family: 'Tungsten-Medium', arial, sans-serif;
  font-size: 40px;
  line-height: 40px;
  color: #33a23c;
  letter-spacing: 0em;
`;

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Tungsten-Medium', arial, sans-serif;
  color: #666;
  font-size: 20px;
  line-height: 22px;
`;

export const Img = styled.img`
  width: 60px;
  height: auto;
  float: right;
  margin-left: 20px;
  margin-top: 5px;
  @media (max-width: 424px) {
    width: 75px !important;
  }
`;
