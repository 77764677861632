import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { sectionList, FormTypes } from '../../constants/queryFragments';
import GlobalStyles from '../../styles/globalStyles';
import loadable from '@loadable/component';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import * as Styled from './commonPageTemplateStyles';
import BreadcrumbsBlock from '../../common/breadcrumbsBlock/breadcrumbsBlock';
import Hamburger from '../../images/icon-hamburger.png';
import PageLeftMenu from '../../common/pageLeftMenu/pageLeftMenu';
import BlockLeftPanel from '../../common/blockLeftPanel/blockLeftPanel';
import TopBanner from '../../common/topBanner/topBanner';
import Footer from '../../components/footer/footer';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';
import { Helmet } from 'react-helmet';
import logo from '../../images/favicon.png';
const CommonPageTemplate = ({ data, location }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
    if (!location?.hash && typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  const path = location?.pathname?.split('/')[1];
  const [showMenu, setShowMenu] = useState(false);
  const handleMenu = (event) => {
    setShowMenu(!event);
  };
  const [showRequestCallBackProp, setShowRequestCallBackProp] = useState(false);
  const handleRequestCallBack = () => {
    setShowRequestCallBackProp(true);
  };
  let pageType = location?.pathname?.split('/').filter((n) => n)[0];

  const sectionsArray = data?.allContentfulFasGasPlus?.edges[0]?.node?.sections;

  const breadcrumbsTitle = data?.allContentfulFasGasPlus?.edges[0]?.node?.name;
  const metaData = data?.allContentfulFasGasPlus?.edges[0]?.node;
  return (
    <React.Fragment>
      <GlobalStyles />
      <Helmet>
        <title>{'Fas Gas Plus :: ' + metaData?.title}</title>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"></meta>
        {metaData?.metadata && (
          <meta property="og:description" content={metaData?.metadata}></meta>
        )}
        {metaData?.title && (
          <meta property="og:title" content={metaData?.title}></meta>
        )}
        <meta property="og:type" content="website"></meta>
        <meta property="og:image" content={logo}></meta>
        <meta property="og:url" content={process.env.GATSBY_DOMAIN_URL}></meta>
        {metaData?.title && (
          <meta name="twitter:title" content={metaData?.title}></meta>
        )}
        {metaData?.metadata && (
          <meta name="twitter:description" content={metaData?.metadata}></meta>
        )}
        <meta name="twitter:image" content={logo}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        {metaData?.hasIndex === false && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <DefaultHeaderMenu />
      <BootstrapNavbar activeLink={path} />
      <TopBanner path={location?.pathname} />
      <BreadcrumbsBlock
        path={location?.pathname}
        breadcrumbsTitle={breadcrumbsTitle}
      />
      <Styled.CommonPageContainer>
        <Styled.InnerContainer>
          <Styled.BodyLeft>
            <PageLeftMenu path={location?.pathname} />
            <BlockLeftPanel path={location?.pathname} />
          </Styled.BodyLeft>
          <Styled.BodyRight>
            {sectionsArray &&
              sectionsArray?.map((section, index) => {
                if (section?.type && sectionList?.includes(section?.type)) {
                  const SectionComponent = loadable(() =>
                    import(`../../components/${section?.type}/${section?.type}`)
                  );
                  return (
                    <SectionComponent
                      key={index}
                      sectionData={section}
                      handleRequestCallBack={handleRequestCallBack}
                      location={location}
                    />
                  );
                }
              })}
            <Styled.CardStyle>
              <BlockLeftPanel path={location?.pathname} />
            </Styled.CardStyle>
          </Styled.BodyRight>
        </Styled.InnerContainer>
      </Styled.CommonPageContainer>
      <BackToTopScroll/>
      <Footer />
    </React.Fragment>
  );
};

export default CommonPageTemplate;

export const pageQuery = graphql`
  query allCommonPagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulListImagesWithPath: Boolean!
  ) {
    allContentfulFasGasPlus(
      filter: { identifier: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          title
          metadata
          node_locale
          hasIndex
          sections {
            ...PageContent @include(if: $contentfulContent)
            ...ImagesWithPathList @include(if: $contentfulListImagesWithPath)
          }
        }
      }
    }
  }
`;
