import * as React from 'react';
import {
  RightMenuPanelContainer,
  ListContainer,
  LiContainer,
  LinkStyle,
  LinkStyleInternal,
  ArrowImg,
  Arrow,
  SubMenuList,
  SubMenuListItem,
  SubMenuAnchor,
  SubSubMenuAnchor,
  SubMenuAnchorInternal,
  SubSubMenuAnchorInternal,
  ActiveLink,
  ActiveSubLink,
  ActiveSubSubLink,
} from './pageLeftMenuStyle';
import useMenus from '../../hooks/useMenus';
import isExternalUrl from '../../utils';
import { isExternalUrlhref, addTrailingSlash } from '../../utils';
import arrowBlue from './../../images/arrow-blue.png';
import { useLocation } from '@reach/router';
import { Match } from '@reach/router';

const PageLeftMenu = ({ path }) => {
  let result = path.split('/').filter((n) => n);
  const menuItem = useMenus().navigations.filter((navigation) => {
    return navigation.type === result[0];
  })[0];
  const location = useLocation();
  const level1 = location.pathname
    .replaceAll('-', ' ')
    .split('/')
    .filter((n) => n)[1];
  const level2 = location.pathname
    .replaceAll('-', ' ')
    .split('/')
    .filter((n) => n)[2];

  const validateRoute = (route) => {
    const menu = menuItem.subNavigations;
    for (let i = 0; i < menu?.length; i++) {
      for (let j = 0; j < menu[i].subMenusWithPaths?.length; j++) {
        if (menu[i].subMenusWithPaths[j].path === route) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <RightMenuPanelContainer>
      <ListContainer>
        {menuItem?.subNavigations?.map((subNavigation, index) => {
          return (
            <LiContainer key={index}>
              {isExternalUrl(subNavigation.textWithPath.path) ? (
                <LinkStyle
                  getProps={({ isCurrent }) => {
                    return isCurrent ? { style: ActiveLink } : {};
                  }}
                  key={index}
                  href={
                    isExternalUrlhref(!subNavigation.textWithPath.path)
                      ? subNavigation.textWithPath.path
                      : process.env.GATSBY_DOMAIN_URL +
                        subNavigation.textWithPath.path
                  }
                  target="_blank"
                >
                  {subNavigation?.textWithPath?.text}
                </LinkStyle>
              ) : (
                <Match path={subNavigation.textWithPath.path}>
                  {({ match }) => (
                    <LinkStyleInternal
                      style={
                        match
                          ? validateRoute(subNavigation.textWithPath.path)
                            ? {}
                            : ActiveLink
                          : {}
                      }
                      to={
                        isExternalUrlhref(subNavigation.textWithPath.path)
                          ? subNavigation.textWithPath.path
                          : addTrailingSlash(process.env.GATSBY_DOMAIN_URL +
                            subNavigation.textWithPath.path)
                      }
                    >
                      {subNavigation?.textWithPath?.text}
                    </LinkStyleInternal>
                  )}
                </Match>
              )}
              <Arrow>
                <ArrowImg src={arrowBlue} />
              </Arrow>
            </LiContainer>
          );
        })}
      </ListContainer>
    </RightMenuPanelContainer>
  );
};

export default PageLeftMenu;
